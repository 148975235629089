$primary: #072d21;
$gray-500: #b2b2b2;
$blue: #0099b1;
$pink: #c4006a;
$purple: #52297f;
$red: #e30613;
$yellow: #f2cb12;
$body-color: #000;
$link-color: $pink;
$font-family-sans-serif: 'Open Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-weight-base: 300;
$headings-font-family: Economica, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$headings-color: $pink;
$h1-font-family: RiptCureLight, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; $spacer: 3rem;
$h1-font-size: 6.4rem;
$h1-font-weight: 400;
$paragraph-margin-bottom: $spacer/2;
$line-height-base: 1.75;
$dropdown-link-active-color: #16181b;
$dropdown-link-active-bg: #fff;

@import "library/_ript-cure-fonts";
@import "../images/main.sprite.scss";

/*!
 * Bootstrap v4.0.0-beta.2 (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/print";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
//@import "../../node_modules/bootstrap/scss/code";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
//@import "../../node_modules/bootstrap/scss/button-group";
//@import "../../node_modules/bootstrap/scss/input-group";
//@import "../../node_modules/bootstrap/scss/custom-forms";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
//@import "../../node_modules/bootstrap/scss/card";
//@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
//@import "../../node_modules/bootstrap/scss/badge";
//@import "../../node_modules/bootstrap/scss/jumbotron";
//@import "../../node_modules/bootstrap/scss/alert";
//@import "../../node_modules/bootstrap/scss/progress";
//@import "../../node_modules/bootstrap/scss/media";
//@import "../../node_modules/bootstrap/scss/list-group";
//@import "../../node_modules/bootstrap/scss/close";
//@import "../../node_modules/bootstrap/scss/modal";
//@import "../../node_modules/bootstrap/scss/tooltip";
//@import "../../node_modules/bootstrap/scss/popover";
//@import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/utilities";

@import "library/bootstrap-4-navbars";
@import "library/_flexslider";
@import "library/image-gallery";

b,strong{
    font-weight:$font-weight-bold;
}

body {
    background: url(../images/grid-bg.png) repeat;
}

header.site-header {
    background-color: $primary;
    padding: 10px 0;
    @include clearfix();

    .navbar{
        padding:0;
    }
}

#logo {
    display: block;
    width: 115px;
    float: left;
}

nav.navbar {
    li.nav-item {
        display: inline;
    }

    a.nav-link:link,
    a.nav-link:visited {
        font-size: 20px;
        font-family: $headings-font-family;
        color: #fff;
        text-decoration: none;
        margin-left: 25px;
    }
}

.dropdown-menu {
    padding-top:0;
}

.dropdown-item {
    color: $black;
}

.dropdown-parent {
    background: $gray-500;
    color:$white;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;

    &.active,
    &:hover {
        background: $gray-500;
        color: $black;
    }
}

h1, h2, h3, h4, h5, h6 {
    a:link,
    a:visited {
        color: inherit;
    }
}
/*
a.grey:link, a.grey:visited {
    display: block;
    color: $gray-500;
    font-family: $headings-font-family;
    font-size: 24px;
    margin-bottom: 10px;
}
*/
.blueblock,
a.blueblock:link,
a.blueblock:visited {
    width: auto;
    background-color: $blue;
    color: #fff;
    padding: 5px;
    font-family: $headings-font-family;
    font-size: 24px;
}

.rte{
    img{
        max-width:100%;
        height:auto !important;
        margin:.5rem;
    }
}

// add background to title at top of image
.title1_top,
.title2_top,
.title3_top {
    display: block;
    border: 3px solid $blue;
    background: $white;
    position: absolute;
    top: -46px;
    left: 44px;
    min-height: 46px;
    
    font-size: 1.8rem;

    &:before {
        content: '';
        border: 3px solid $blue;
        background: $white;
        width: 26px;
        height: 46px;
        position: absolute;
        left: -26px;
        bottom: -22px;
    }

    &:after {
        content: '';
        background: $white;
        width: 3px;
        height: 19px;
        position: absolute;
        right: -3px;
        bottom: 0;
    }

    > span,
    a > span{
        display:block;
        padding:.2rem .75rem;

        &:before {
            content: '';
            background: $white;
            width: 3px;
            height: 21px;
            position: absolute;
            left: -3px;
            bottom: 0;
        }

        &:after {
            content: '';
            border: 3px solid $blue;
            background: $white;
            width: 26px;
            height: 25px;
            position: absolute;
            right: -26px;
            bottom: -3px;
        }
    }
}

.title2_top {
    background: url(../images/title-bg2.png) no-repeat;
    background-size: 100%;
    left: -8%;
    top: -16%;
}

.title2_top a:link, .title2_top a:visited {
    color: $black;
}

.title1_bottom {
    position: absolute;
    bottom: -24px;
    left: 21px;
}

.title1_bottom {
    @include main-title-bg1-bottom();
}

.title2_bottom { // background image to allow for text over top
    border:3px solid $blue;
    background:$white;
    font-size: 14px;
    padding:1rem;
}

.title1_bottom img, .title2_bottom img {
    width: auto;
}

.title3_top {
    left:73px;

    &:before {
        background: $white;
        width: 100px;
        height: 27px;
        position: absolute;
        left: -76px;
        bottom: -27px;
    }

    > span {
        &:before {
            width: 21px;
            height: 3px;
            position: absolute;
            left: 0;
            bottom: -3px;
        }

        > span {
            &:before {
                content: '';
                border: 3px solid $blue;
                background: $white;
                width: 50px;
                height: 27px;
                position: absolute;
                left: -53px;
                bottom: -51px;
            }

            &:after {
                content: '';
                background: $white;
                width: 44px;
                height: 3px;
                position: absolute;
                left: -50px;
                bottom: -27px;
            }
        }
    }
}

/*a.big:link, a.big:visited, p.big {
    color: $primary;
    font-weight: bold;
    display: block;
    font-family: $headings-font-family;
    font-size: 28px;
}

a.big:hover {
    color: $pink;
    text-decoration: none;
}*/

.alignleft {
    float: left;
    margin: 0 10px 10px 0;
}

.alignright {
    float: right;
    margin: 0 0 10px 10px;
}

h1,
.blog-banner {
    padding: 7rem 0 2rem 0;
    text-align: center;
    background-color: $pink;
    color: #fff;
    font-family: $h1-font-family;
    font-weight: $h1-font-weight;

    &.colour-blue {
        background-color: $blue;
    }

    &.colour-purple {
        background-color: $purple;
    }

    &.colour-red {
        background-color: $red;
    }

    &.colour-yellow {
        background-color: $yellow;
    }

    &.home-banner {
        padding-top: 2rem;
    }

    span {
        position: relative;
        top: -2.63rem;
    }
}
.blog-banner {
    background-color: $yellow;
    font-size: $h1-font-size;
    margin-bottom: 1.5rem;
    line-height: 1.2;
}

// --- homepage specific --- 

.flexslider {
    margin: 0;

    ul{
        margin:0;
        padding:0;
    }

    li{
        margin:0;
        padding:0;
        list-style:none;
        display:none;

        img{
            width:100%;
        }

        &:first-child{
            display:block;
        }
    }
}

.summary-block {
    margin-bottom: 1.5rem;
    position: relative;
    border-top: 46px solid transparent;
    border-bottom: 25px solid transparent;

    a{
        text-decoration:none;
    }

    .left{
        position:relative;

        a img {
            width: 100%;
            height: auto;
        }
    }

    .content {
        background: #fff;
        height: 100%;
        padding: 30px 30px 30px 60px;
        margin-left: -30px;

        h3 {
            font-size: 2.25rem;
        }
    }

    p.more {
        font-weight: 700;
    }
}

.image-link-block {
    border-top:46px solid transparent;
    border-bottom:25px solid transparent;
    position:relative;
    margin-bottom:1.5rem;

    img{
        width:100%;
        max-width:445px;
    }
}

.image-text-link-block {
    border-top: 46px solid transparent;
    position: relative;
    margin-bottom: 1.5rem;

    .title1_top {
        left: 23px;
        right: 23px;
    }

    .img-cntr {
        padding: 0 25px 0 26px;
    }

    img {
        width: 100%;
    }

    a{
        text-decoration:none;
        color:$body-color;
    }
}

.twitter-feed {
    border-top: 46px solid transparent;
    position: relative;
    margin-bottom: 1.5rem;
    height:100%;

    .inner {
        background: $white;
        margin-left:73px;
        padding:2rem 1rem 1rem;
        height:100%;
    }

    ul{
        margin:0;
        padding:0;
        list-style:none;
        position:relative;
    }

    li{
        display:none;
        background:$white;
        transition:opacity ease-out 1s;

        &:first-child{
            display:block;
        }

        &.current,
        &.next,
        &.inactive{
            display:block;
            position:absolute;
            top:0;
            left:0;
            z-index:1;
        }
        &.inactive{
            opacity:0;
            z-index:0;
        }
        &.current,
        &.next{
            opacity:1;
        }
        &.current{
            z-index:1;
        }
        &.next{
            z-index:2;
        }
    }

    .status{
        display:block;
    }

    .header{
        display:block;
        background:$blue;
        color:$white;
        padding:0.3rem 0.5rem;
        text-align:right;

        a{
            font-weight:700;
            color:$white;
            float:left;
        }
    }

    .retweet{
        > .header{
            background:none;
            color:$black;
            text-align:left;

            a{
                float:none;
                color:$black;
            }
        }
    }

    .body{
        img{
            max-width:100%;
        }
    }
}



/* --- gallery --- */

#gallery_banner {
    background-color: #52297f;
}

.gallery_cover, #gallery li {
    position: relative;
    width: 249px;
    float: left;
    margin: 7% 0% 3% 17%;
}

.gallery_cover {
    margin: 7% 0% 11% 13%;
}

.gallery_cover img, #gallery li img {
    width: 100%;
    height: auto;
}

.gallery_cover h3 a:link, .gallery_cover h3 a:visited {
    color: $pink;
}

.gallery_cover .title2_bottom {
    background-image: none;
    background: #fff;
    border: 3px solid #0099b1;
    z-index: 1;
    padding: 5% 9%;
}

.framed {
    border: 15px solid $gray-500;
    border-radius: 43px;
    position: relative;
    overflow:hidden;
    margin-bottom:1.5rem;

    &:before {
        content: '';
        @include main-spigot-top();
        display: block;
        position: absolute;
        left: 25%;
        top: 0;
    }

    &:after {
        content: '';
        @include main-spigot-top();
        display: block;
        position: absolute;
        right: 25%;
        top: 0;
    }

    .fr-one {
        &:before {
            content: '';
            @include main-spigot-left();
            display: block;
            position: absolute;
            left: 0;
            top: 67px;
        }

        &:after {
            content: '';
            @include main-spigot-left();
            display: block;
            position: absolute;
            left: 0;
            top: 208px;
        }
    }

    .fr-two {
        &:before {
            content: '';
            @include main-spigot-left();
            display: block;
            position: absolute;
            left: 0;
            top: 393px;
        }

        &:after {
            content: '';
            @include main-spigot-left();
            display: block;
            position: absolute;
            left: 0;
            top: 769px;
        }
    }

    .fr-three {
        &:before {
            content: '';
            @include main-spigot-right();
            display: block;
            position: absolute;
            right: 0;
            top: 249px;
        }

        &:after {
            content: '';
            @include main-spigot-right();
            display: block;
            position: absolute;
            right: 0;
            top: 390px;
        }
    }

    .fr-four {
        &:before {
            content: '1';
            display: block;
            position: absolute;
            left: 0;
            top: 81px;
            width: 16px;
            height: 27px;
            color: $white;
            background: $gray-500;
            text-align: center;
        }

        &:after {
            content: '3';
            display: block;
            position: absolute;
            left: 0;
            top: 222px;
            width: 16px;
            height: 27px;
            color: $white;
            background: $gray-500;
            text-align: center;
        }
    }

    .fr-five {
        padding: 28px 23px 28px 32px;

        &:before {
            content: '2';
            display: block;
            position: absolute;
            right: 0;
            top: 128px;
            width: 21px;
            height: 27px;
            color: $white;
            background: $gray-500;
            text-align: center;
        }

        &:after {
            content: '4';
            display: block;
            position: absolute;
            right: 0;
            top: 168px;
            width: 21px;
            height: 27px;
            color: $white;
            background: $gray-500;
            text-align: center;
        }
    }

    .inner {
        border: 3px solid #0099b1;
        background: $white;
        padding: 3rem 2rem;
    }
}

// --- team members

.team-member {
    border-top: 46px solid transparent;
    border-bottom: 25px solid transparent;
    position:relative;
    margin-bottom:1.5rem;

    &:after {
        content: '';
        @include main-team-member-bottom();
        position: absolute;
        bottom: -24px;
        right: 21px;
    }

    h2,
    img,
    .bio {
        cursor: pointer;
    }

    img {
        width: 100%;
    }

    .bio {
        display: block;
        background-color: $white;
        padding: 1.5rem 1.5rem 0.5rem;
        border: 1px solid $gray-500;
    }
}

// --- blog --- 

.blog-intro {
    text-align: center;
}

.article-listing {
    &:hover {
        text-decoration: none;
    }

    .blueblock {
        position: absolute;
        bottom: -59px;
        left: -25px;
        z-index: 1;
        background: url(../images/continue.png) no-repeat;
        padding: 27px 15px 10px 30px;
    }
}

article {
    padding: 1rem 1.5rem;
    border: 3px solid $blue;
    margin-bottom: 4.5rem;
    background-color: $white;
    position: relative;
    z-index: 2;
    @include clearfix;

    .post-image {
        width: 100%;
        margin-bottom: 1rem;
    }

    h1 {
        background: 0;
        color: #000;
        padding: 0;
        font-size: 2.5rem;
        text-align: left;
        font-family: $headings-font-family;
        margin-bottom: 0.5rem;
    }

    span.date {
        font-family: $headings-font-family;
        color: $gray-500;
    }

    img.thumb {
        width: 100%;
    }

    .post-footer {
        margin-bottom: 1rem;
    }
}

.sidebar {
    padding: 1rem 1.5rem;
    border: 3px solid $blue;
    background: $white;
}

.widget {
    margin-bottom:1.5rem;

    h3{
        margin-bottom:0.5rem;
    }

    ul {
        margin: 0 0 10px;
        padding:0;
    }

    li {
        list-style: none;
    }
}

//----------------------------
//				 Tag cloud
//----------------------------
.tagcloud {
    white-space: normal;

    li {
        display: inline-block;
        border-bottom: none;

        a {
            margin-right: 0.25rem;
            display: inline;
            padding: 0.25rem 0.1rem 0.25rem 0;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .tag-cloud-5 a {
        font-size: 1.4rem;
        color: $pink;
    }

    .tag-cloud-4 a {
        font-size: 1.3rem;
        color: $blue;
    }

    .tag-cloud-3 a {
        font-size: 1.1rem;
        color: $red;
    }

    .tag-cloud-2 a {
        font-size: 1rem;
        color: $purple;
    }

    .tag-cloud-1 a {
        font-size: 0.75rem;
        color: $yellow;
    }
}

.recent-posts {
    li {
        margin-bottom: 1rem;
    }

    .thumb {
        float: left;
    }

    li.has-thumb .txt {
        margin-left: 60px;
        display: block;
    }

    .time {
        display: inline-block;
        width: 100%;
        color: $gray-500;
        font-family: $headings-font-family;
        float:left;
        line-height:1;
        margin-bottom:0.15rem;
    }

    .name {
        display: inline-block;
        width: 100%;
        line-height:1.3;
    }
}

// --- contact page ---

.contact {
    border-top: 46px solid transparent;

    .inner {
        position: relative;
        background-color: $white;
        border: 3px solid $blue;
        padding: 3rem 2rem;
    }
}

.call {
    color: $gray-700;
    margin: 3rem 0;
    
    p {
        text-align: center;
        font-family: $headings-font-family;
        font-size: 36px;
    }
}

.wpcf7 p {
    background-color: #b1b1b1;
    display: block;
    margin-bottom: 10px;
    color: #fff;
    padding: 5px;
    font-family: $headings-font-family;
    font-size: 24px;
    overflow: hidden;
}

.wpcf7 input[type=text], .wpcf7 input[type=email], .wpcf7 textarea {
    height: 20px;
    font-size: 18px;
    background-color: #b1b1b1;
    border: 0;
    font-family: arial, sans-serif;
    line-height: 24px;
    padding: 0 5px 4px;
}

.wpcf7 textarea {
    height: 180px;
    margin-left: 68px;
    width: 79%;
    margin-top: -27px;
}

.wpcf7 form {
    position: relative;
}

.wpcf7 #form-submit {
    background: none;
    background: url(../images/send.png) no-repeat;
    display: block;
    position: absolute;
    right: -34px;
    bottom: -73px;
    width: 163px;
    height: 55px;
}

.wpcf7 input[type=submit] {
    background: none;
    border: 0;
    color: #fff;
    font-size: 22px;
    margin-top: 21px;
    font-family: $headings-font-family;
    margin-left: 42px;
    cursor: pointer;
}

.wpcf7 span.wpcf7-not-valid-tip {
    top: -4%;
    left: 1%;
    z-index: 100;
    font-size: 15pt;
    width: 280px;
    padding: 2px;
    height: 25px;
}

// --- footer ---
footer {
    color: #fff;
    padding: 3rem 0;
    margin-bottom: 1rem;
    clear: both;
    position: relative;

    &:before {
        content: '';
        display: block;
        background: $primary;
        width: 4rem;
        position: absolute;
        width: 100%;
        height: 3rem;
        bottom: 3rem;
    }

    .container {
        background: $primary;
        @include clearfix();
        padding: 3rem 0;
        position: relative;

        &:before {
            content: '';
            display: block;
            background: $primary;
            width: 4rem;
            position: absolute;
            left: -4rem;
            top: -3rem;
            bottom: -3rem;
        }

        &:after {
            content: '';
            display: block;
            background: $primary;
            width: 4rem;
            position: absolute;
            left: -8rem;
            height: 6rem;
            bottom: 0;
        }
    }

    .inner {
        position: relative;
        width: 100%;
        padding: 0 1rem;

        &:before {
            content: '';
            display: block;
            background: $primary;
            width: 4rem;
            position: absolute;
            right: -4rem;
            height: 6rem;
            bottom: -3rem;
        }

        &:after {
            content: '';
            display: block;
            background: $primary;
            position: absolute;
            left: 0;
            height: 3rem;
            width: 12rem;
            bottom: -6rem;
        }
    }

    p {
        font-size: 0.75rem;
        margin-bottom: .2rem;
    }

    .logo {
        img {
            max-width: 138px;
        }
    }

    .info {
        padding-top: 1rem;
        flex-grow: 1;
    }

    .social-links {
        padding-top: 1rem;

        a {
            margin-left: 15px;
            &:first-child{
                margin-left:0;
            }
        }
    }

    @include media-breakpoint-up(sm) {
        .inner {
            display: flex;
        }
        .logo{
            padding-right:1rem;
        }
        .social-links {
            padding-left: 4rem;
        }
    }
}

/* ------------ RESPONSIVE MEDIA QUERIES ------- */

/*@media (max-width: 1024px) {
    header {
        padding: 18px 12%;
        height: 100%;
    }

    .framed {
        margin: 4% 4%;
        padding: 6% 7% 39% 8%;
        width: 78%;
        background-size: 100%;
    }

    #main.framed {
        width: 636px;
        background-size: auto;
        padding: 6% 11%;
    }

    .gallery_cover {
        margin: 7% 0% 17% 10%;
    }

    .title3_top {
        padding: 3% 2% 40% 47%
    }
}

@media (max-width: 915px) {
    .gallery_cover {
        margin: 7% 0% 22% 7%;
    }
}

@media (max-width: 851px) {
    .gallery_cover {
        margin: 7% 0% 22% 29%;
    }
}*/

/* iPad portrait */
/*@media (max-width: 768px) {

    header {
        padding: 18px 8%;
        height: 100%;
    }

    #main.framed {
        width: auto;
    }

    .framed {
        background: #fff;
        margin: 4% 5%;
        padding: 7%;
        width: 70%;
        border: 2px solid #0099b1;
        height: auto;
    }

    .banner {
        padding: 4% 0 1.5% 0;
    }

    .banner img {
        width: 40%;
    }

    #team li {
        padding: 1px 0 20px 22px;
        width: 268px;
        height: 356px;
        margin: 0 18px 0 18px;
        background-size: 100%;
    }

    #team img {
        width: 244px;
        height: auto;
    }

    #team h2 {
        padding: 3px 0 5px 20px;
    }

    #work .framed, #contact .framed {
        width: 92%;
        padding: 8px;
    }

    .gallery_cover {
        margin: 7% 0% 22% 17%;
    }
}*/

/* eg: Android Samsung galaxy landscape */
/*@media (max-width: 685px) {
    p {
        font-size: 14px;
    }

    header {
        padding: 18px 8% 9px 8%;
    }

    nav#site-navigation {
        display: none;
    }

    nav#mobile {
        display: block;
    }

    #latest-post .content a.title:link, #latest-post .content a.title:visited {
        font-size: 24px;
    }

    #team {
        width: 321px;
    }

    #team li {
        float: none;
        padding: 7px 0 20px 22px;
    }

    .gallery_cover {
        margin: 7% 0% 28% 15%;
    }
}*/

/* iphone 5 landscape */
/*@media (max-width: 568px) {
    p {
        font-size: 14px
    }

    header {
        padding: 10px 8% 0px 8%;
    }

    #logo {
        width: 90px;
    }

    #logo img {
        width: 100%;
        height: auto;
    }

    .title-big {
        background-image: none;
        background-color: #fff;
        border: 3px solid #0099b1;
        width: 80%;
        padding: 3px 0 0 15px;
        height: 47px;
    }

    footer .inner {
        padding: 4% 4% 6%;
    }

    footer .inner .info {
        width: 53%;
    }

    .gallery_cover {
        margin: 12% 0% 37% 10%;
    }

    .gallery_cover, #gallery li {
        width: 70%;
    }

    .title2_bottom {
        bottom: -23%;
    }
}*/

/* iPhone 3+4 landscape */
/*@media (max-width: 480px) {
    h2 {
        font-size: 20px;
    }

    .gallery_cover, #gallery li {
        float: none;
        width: 80%;
    }

    nav#mobile .menu {
        margin-top: 12px;
    }

    footer {
        background: $primary;
    }

    footer .inner {
        margin: 0;
    }

    footer .inner .info {
        line-height: 12px;
        width: 71%;
        margin: 0 0 4% 5px;
    }

    footer .inner .info p {
        text-align: right;
    }

    footer #social-links {
        float: none;
        clear: both;
        text-align: center;
    }

    .title1_top {
        background-size: 52%;
        top: -28px;
    }

    .title3_top {
        background-size: 52%;
        top: -8.5%;
        padding: 1% 2% 20% 28%;
    }

    .title1_bottom {
        bottom: -16px;
    }

    .title1_bottom img, .title2_bottom img {
        width: 70%;
    }

    #blog, #blog-sidebar {
        float: none;
        width: 90%;
        margin: 0 auto;
    }

    #blog-sidebar {
    }

    .alignleft, .alignright {
        width: 100%;
        height: auto;
    }

    div.alignright {
        float: none;
        margin: 0;
    }
}*/

/* eg: Android Samsung galaxy portrait */
/*@media (max-width: 380px) {

    #gallery-homepage, #twitter {
        float: none;
        margin: 0 auto;
        width: 80%;
    }

    #twitter {
        margin-top: 19%;
    }
}*/

/* iPhone 3+4 portrait */
/*@media (max-width: 320px) {
    #team li {
        margin: 0;
    }
}*/

/* small android portrait */
/*@media (max-width: 240px) {
    .title1_top {
        top: -21px;
        height: 33px;
        padding: 3px 0 0 14px;
        font-size: 14px;
    }

    .title3_top {
        font-size: 14px;
    }
}*/