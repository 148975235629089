/*
@import this file directly into your existing Scss files to use these mixins
*/
@mixin main-spigot-left() {
	width: 32px;
	height: 15px;
	display: inline-block;
	background: url('/content/images/main.sprite.png') -2px -2px no-repeat;
}
@mixin main-spigot-right() {
	width: 23px;
	height: 16px;
	display: inline-block;
	background: url('/content/images/main.sprite.png') -2px -19px no-repeat;
}
@mixin main-spigot-top() {
	width: 16px;
	height: 15px;
	display: inline-block;
	background: url('/content/images/main.sprite.png') -2px -37px no-repeat;
}
@mixin main-title-bg1-bottom() {
	width: 52px;
	height: 45px;
	display: inline-block;
	background: url('/content/images/main.sprite.png') -2px -54px no-repeat;
}
@mixin main-team-member-bottom() {
	width: 52px;
	height: 45px;
	display: inline-block;
	background: url('/content/images/main.sprite.png') -2px -101px no-repeat;
}
